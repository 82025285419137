$light-gray: #eee;

.user-search {
  li {
    cursor: pointer;

    &:hover {
      background: $light-gray;
    }
  }
}
