.dcr-container{
    font-family: 'Open Sans';
    width: 100%;
    
}
.dcr-dealer-detail-column-header{
    height: 50px;
    padding: 13px;
    font-weight: bold;
    background-color: #F8FAFB;
    border: 1px solid #DBE1EC;
    color: #596981;
    font-size: 14px;
}
.margin-horizontal{
    margin-left: 10px;
    margin-right:10px;
}

.dcr-dealer-detail-column-body{
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 585px;
}
.dcr-dealer-detail-col-lg{
    flex: 2;
    position: relative;
}
.dcr-dealer-detail-col-sm{
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
}
.dcr-dealer-name{
    font-size: 16px;
    color:#3D515A

}
.dcr-dealer-status{
    font-size: 12px;
    color:#3D515A;
    display: flex;
    align-items: center;
}
.dcr-dealer-detail-text-normal{
    font-size: 12px;
    color: #52636B;
}
.billing-address-body{
    color:#3D515A;
    font-size: 12px;
}
.billing-address-heading{
    color:#3D515A;
    font-weight: 600;
    font-size: 16px;
}
.billing-address{
    position: absolute;
    bottom: 20px;
}
.dcr-detail-item-text{
    font-size: 16px;
    padding-bottom: 28px;
    color: #3D515A;
    display: flex;
    overflow-wrap: anywhere;
    margin-left: 10px;
    align-items: center;
}
.dcr-image{
    height: 7rem;
    width: 90%;
    border-radius: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: cover;
  
}
.dcr-detail-item-title{
    flex: 2; 
}
.dcr-detail-item-body{
    flex: 6;
}
.dcr-dropdown-container{
    padding: 10px;
}
.dcr-filter-text{
    display: flex;
}
.dcr-alphabets{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: 12px;
    cursor: pointer;
}
.dcr-progress-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.dcr-white-label{
    font-size: 12px;
    align-self: flex-end;
    margin-right: 10px;
}
.dcr-white-label-checkbox{
    height: 20px;
    width: 20px;
    margin-left: 5px;
}
.status-inactive{
    height: 12px;
    width: 12px;
    border-radius: 6px;
    background-color: red;
    margin-left: 5px;
}
.status-active{
    height: 12px;
    width: 12px;
    border-radius: 6px;
    background-color: green;
    margin-left: 5px;
}
.btn-icons{
    height: 28px;
    // width: 50px;
    cursor: pointer;
    margin-right: 40px;
}
.dcr-btn-icons{
    flex-direction: row;
    align-self: flex-end;
    display: flex;
}
.status-btn-container{
    margin-right: 20px;
}
.edit-dcr-container{
    flex: 1;
    display: flex;
    flex-direction: column;
}
.edit-dcr-form-field-container{
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
}
.edit-dcr-row{
    flex:1;
    display: flex;
    padding: 10px;
    margin-bottom: 10px;
}
.flex-center{
    justify-content: center;
}
.edit-dcr-form-label{
    flex:1
}
.edit-dcr-form-field{
    flex:3;
    display: flex;
}
.react-multiple-carousel__arrow--right {
    right:10px !important
}
.react-multiple-carousel__arrow--left{
    left:10px !important
}
.edit-google-dcr-title{
    padding:10px;
    display: flex;
}
.hidden-upload{
    opacity: 0;
    position: absolute;
    z-index: 1;
    left:0
}
.upload-btn-container{
    position: relative;
    margin-left: 10px;
}
.edit-dcr-close-btn{
    position: absolute;
    right: 24px;
    top:24px;
    cursor: pointer;
}
.edit-dcr-title{
    position: absolute;
    left: 24px;
    top:24px;
    cursor: pointer;
}
.edit-dcr-action-button-right{
    position: absolute;
    right: 24px;
    bottom:24px;
    cursor: pointer;
}

.arrow-icons{
    height: 10px;
    width:10px;
}
.arrow-right{
    height: 20px;
    width: 10px;
    display :inline-block;
    position: relative;
  }
.arrow-right::after {
content: "";
height: 10px;
width: 10px;
top: 5px;
border-width: 2px 2px 0 0;
border-color: #3D515A;
border-style: solid;
transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
position: absolute;
}
.arrow-right:disabled{
    border-color: #ccc;
}
.arrow-left{
    height: 20px;
    width: 10px;
    display :inline-block;
    position: relative;
  }
.arrow-left::after {
    content: "";
    height: 10px;
    width: 10px;
    top: 5px;
    border-width: 2px 2px 0 0;
    border-color: #3D515A;
    border-style: solid;
    transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0) rotate(180deg);
    position: absolute;
}
.disabled{
    opacity: 0.5;
}


.error-string{
    color: rgb(182, 2, 2);
}
.edit-dcr-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    padding: 5px;
}
.edit-dcr-btn{
    margin-left: 10px;
    margin-right: 10px;
}
.no-img-available{
    margin: 20;
    display: flex;
    justify-content: center;
    align-items: center;
}
.edit-dcr-time-row-container{
    display: flex;
    flex-direction: column;
}
.edit-dcr-time-row{
    display: flex;
    justify-content: space-around;
    margin: 10px;
}
.edit-dcr-time-row-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;
    
}
.edit-dcr-time-row-day{
    flex: 1;
    display: flex;
    align-items: center;
}
.edit-dcr-time-row-input{
    flex: 2;
    padding-left: 10px;
    padding-right:10px;
    display: flex;
    align-items: center;
}
.edit-dcr-time-field{
    display: flex;
    flex: 3;
    align-items: center;
    height: 57px;
    justify-content: space-between;
}
.spacer{
    flex-grow: 1;
}
.checkbox-input{
    height: 57px;
    flex: 1;
    display: flex;
    justify-content: space-around;
}
.hour-footer{
    margin-top: 50px;
    margin-bottom: 50px;
}
.progress-indicator-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
}
.loader-container{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background-color: white;
    opacity: 0.7;
}
.dcr-link{
    cursor: pointer;
    color: #0070B9;
    margin-left: 5px;
}

.label-overflow {
    text-overflow: ellipsis;
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
}

.label-overflow:hover{
    overflow: visible; 
    white-space: normal;
    height:auto;  /* just added this line */
}


@media only screen and (max-width: 1200px) {
    .dcr-header-label  label {
        font-size: 12px;
    }
}
