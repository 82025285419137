div {
  font-family: 'Open Sans';
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.version-table {
  border: 1;
  margin: 10px;
  font-size: 1.1rem;
  text-align: center;
}

.version-table-text {
  text-align: center;
  padding: 5px;
}

.region-icon {
  width: 25px;
  height: auto;
  border-radius: 3px;
}
.no-border {
  border-style: solid;
  border-width: thin;
}

.footer-main {
  margin: 2px;
}

.card-content-align {
  text-align: left;
  margin-bottom: 0;
}

.heading-left {
  text-align: left;
}

.MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  margin: 0 0 16px;
}

.MuiTablePagination-actions {
  margin: 0 0 16px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
