@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.cars {
  .card-content {
    position: relative;
    width: 15rem;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }

    .car-menu {
      position: absolute;
      right: 0.25rem;
      top: 0.25rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      button {
        padding: 0.125rem;
      }
    }

    .small-font {
      font-size: 0.875rem;
    }

    .success {
      color: green;
      font-weight: bold;
    }

    .error {
      color: red;
      font-weight: bold;
    }

    .warning {
      color: orange;
      font-weight: bold;
    }
  }
}
