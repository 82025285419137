.contactus-table-container{
    height: 80vh;
    overflow-y: scroll;
}
tr {
    height: 60px;
}
td {
    min-height: 60px;
}
