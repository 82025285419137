.ugd-map-conatiner{
    height: 45vh;
    width: 100%;
}
.ugd-search-container{
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ugd-search{
    flex: 0.5;
}
.ugd-card{
    width: 280px;
    height: 300px;
    margin-right: 10px;
    position: relative;
    cursor: pointer;
}
.ugd-card-image{
    height: 140px;
    width: 100%;
    object-fit: cover;

}
.ugd-card-details-container{
    padding: 10px;
}
.ugd-card-details-footer{
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
}
.ugd-card-dealer-name{
    font-size: 16px;
    font-weight: bold;
    color: #13223C;
}
.ugd-card-dealer-detail{
    font-size: 14px;
    
    color: #3D515A;
}
.ugd-dealer-list-container{
    padding-top: 10px;
    padding-bottom:10px;
}
.ugd-select-btn{
    border-radius: 30px;
    height: 30px;
    line-height: 0.5;
}
.selected{
    border-color: hotpink;
    border-width: 2px;
}
.gray-bg{
    background-color: #ccc;
    height: 140px;
}
